export default {
    id: null,
    name: null,
    description: null,
    type: null,
    required: true,
    config: {
        labelBold: false,
        inputBold: false,
        readonly: false,
        tip: null,
        placeholder: null,
        defaultValue: null,
        labelPosition: 'tl',
        width: null,
        height: null,
        layout: 'inline', //inline/newline
        pretitle: null,
        optionValues: ''
    },
    order: 100,
    active: true,
    form: null,
    topic: null
}
